import React, {useEffect, useReducer, useState} from 'react';
import {db} from '../firebase';
import {collection, limit, onSnapshot, orderBy, query, startAfter, where} from 'firebase/firestore';
import '/node_modules/react-grid-layout/css/styles.css'
import '/node_modules/react-resizable/css/styles.css'
import RequestStack from './RequestStack';
import {Button} from "@mui/material";
import {approvalReducer, diffusionApprovalState} from "../services/approvalReducer";

const FeedRequestGrid = () => {
    const [requests, setRequests] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [page, setPage] = useState(1);
    const [lastVisible, setLastVisible] = useState(null);
    const itemsPerPage = 50;

    useEffect(() => {
        const fetchData = async () => {

            let docQuery;

            if (page < 2) {
                docQuery = query(
                    collection(db, 'feed'),
                    where('status', '==', 'pending'),
                    orderBy('submittedAt', 'desc'),
                    limit(itemsPerPage)
                );
            } else {
                docQuery = query(
                    collection(db, 'feed'),
                    where('status', '==', 'pending'),
                    orderBy('submittedAt', 'desc'),
                    startAfter(lastVisible),
                    limit(itemsPerPage)
                );
            }

            onSnapshot(docQuery,
                (snapshot) => {
                    const source = snapshot.metadata.hasPendingWrites ? "local" : "server";
                    console.log(`onSnapshot from ${source}`);
                    console.log(`Number of items = ${snapshot.docs.length}`);
                    const requests = snapshot.docs.map((doc) => ({id: doc.id, ...doc.data()}));

                    const lastVisibleDocument = snapshot.docs[snapshot.docs.length - 1];
                    setLastVisible(lastVisibleDocument);
                    setRequests(requests);
                },
                (error) => {
                    console.log(`Error = ${error}`);
                }
            );
        };

        fetchData().then();

    }, [page]);

    const [approvalState, dispatch] = useReducer(approvalReducer, diffusionApprovalState, undefined);

    return (
        <div>
            <RequestStack requests={requests}/>
            <Button className="button" onClick={() => {
                setPage(prev => prev + 1);
                dispatch({type: 'SUBMIT'});
            }}>Load More</Button>
        </div>
    )
}

const RequestGrid = () => (
    <FeedRequestGrid/>
)

export default RequestGrid;