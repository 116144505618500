import { db, bucketStorage } from "../firebase";
import { 
    setDoc, 
    doc, 
    deleteDoc
} from "firebase/firestore";
import { ref, getBlob, uploadBytes, deleteObject, getDownloadURL } from "firebase/storage"
import { getAuth } from "firebase/auth";
import { DateTime } from 'luxon';

export function formatDate(date) {
    const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        timeZoneName: "short"
    };

    return date.toLocaleString('en-US', options);
}

export function fromFirebase(dateStr) {
    return new Date(dateStr);
}

export function approveRequest(request) {
    request['approvedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
    request['approvedBy'] = getAuth().currentUser.uid;

    setDoc(doc(db, "activeFeed", request.id), request);

    deleteDoc(doc(db, "feedRequests", request.id));

    alert(`Request ${request.id} approved`);
}

export function denyRequest(request) {
    request['deniedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
    request['deniedBy'] = getAuth().currentUser.uid;

    setDoc(doc(db, "deniedRequests", request.id), request);

    deleteDoc(doc(db, "feedRequests", request.id));

    alert(`Request ${request.id} denied`);
}

export function flagRequest(request) {
    request['flaggedAt'] = DateTime.utc().startOf('second').toISO({ suppressMilliseconds: true });
    request['flaggedBy'] = getAuth().currentUser.uid;

    setDoc(doc(db, "flaggedWorkflows", request.id), request);

    deleteDoc(doc(db, "feedRequests", request.id));

    alert(`Request ${request.id} flagged`);
}

/**
 * Copies an image from a temporary storage location to a permanent one
 * @param {string} uid - The user ID who owns the image
 * @param {Object} localImageData - Object containing image metadata including URL
 * @param {string} [topFolder="FeedImages"] - Top level folder name to store image in
 * @returns {Promise<string>} URL of the copied image in permanent storage
 */
export const copyToPermanent = async (uid, localImageData, topFolder="FeedImages") => {
    const original_url = localImageData["meta"]["imageURL"]
    if (original_url.includes("FeedImage")) {
        console.log("Already in Permanent");
        return original_url;
    }
    const nameIdx = original_url.indexOf("/Image")
    const fileUrl = original_url.substring(nameIdx+1)
    const fileNameIdx = fileUrl.lastIndexOf("/")
    const fileName = fileUrl.substring(fileNameIdx+1)

    // console.log(nameIdx, fileUrl, fileName)
    const existingFileRef = ref(bucketStorage, original_url)
    const imgBytes = await getBlob(existingFileRef)
    
    const newRef = ref(bucketStorage, `${topFolder}/${uid}/${fileName}`)
    await uploadBytes(newRef, imgBytes)
    
    const downloadUrl = await getDownloadURL(newRef)

    return downloadUrl
}