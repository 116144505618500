import * as React from 'react';
import Container from '@mui/material/Container';
import axios from 'axios';
import { Button, TextField } from '@mui/material';

import { collection, query, where, getDocs, orderBy, limit, startAfter, setDoc, deleteDoc, writeBatch, doc } from "firebase/firestore";
import { db } from '../firebase';
import { copyToPermanent } from '../components/Utils';

export default function Dashboard() {
    const [daysToKeep, setDaysToKeep] = React.useState(30);

    const moveApprovedImagesToPermant = async () => {
        var currentQuery = query(collection(db, "/feed"), where("status", "==", "approved"));
        var gallaryDocs = await getDocs(currentQuery);

        var batch = writeBatch(db);
        console.log(`Working on approved images, ${gallaryDocs.docs.length}`)
        let i = 0
        for (let currdoc of gallaryDocs.docs) {
            const docData = currdoc.data();
            const currDocRef = doc(db, "feed", docData.id);
            try {
                const uid = docData["submittedBy"];
                const newUrl = await copyToPermanent(uid, docData['workflow'], "FeedImages");
                if (newUrl === docData["workflow"]["meta"]["imageURL"]) {
                    continue;
                }
                docData["workflow"]["meta"]["imageURL"] = newUrl;
            } catch (e) {
                docData["status"] = "expired";
            }
            console.log(`${i} Updated.`)
            batch.set(currDocRef, docData);
        }
        batch.commit();

        currentQuery = query(collection(db, "/feed"), where("status", "==", "pre-approved"));
        gallaryDocs = await getDocs(currentQuery);
        
        batch = writeBatch(db);
        console.log(`Working on pre-approved images, ${gallaryDocs.docs.length}`)
        i = 0;
        for (let currdoc of gallaryDocs.docs) {
            const docData = currdoc.data();
            const currDocRef = doc(db, "feed", docData.id);
            try {
                const uid = docData["submittedBy"];
                const newUrl = await copyToPermanent(uid, docData['workflow'], "FeedImages");
                if (newUrl === docData["workflow"]["meta"]["imageURL"]) {
                    continue;
                }
                docData["workflow"]["meta"]["imageURL"] = newUrl;
            } catch (e) {
                docData["status"] = "expired";
            }
            console.log(`${i} Updated.`)
            batch.set(currDocRef, docData);
        }
        batch.commit();
    }

    const removeExpiredImages = async () => {
        var currentQuery = query(collection(db, "/feed"), where("status", "==", "expired"));
        var gallaryDocs = await getDocs(currentQuery);

        console.log(`expired size: ${gallaryDocs.docs.length}`)
        const batch = writeBatch(db);
        for (let currdoc of gallaryDocs.docs) {
            const docData = currdoc.data();
            if (docData["workflow"]["meta"]["imageURL"].includes("FeedImage")) {
                const currDocRef = doc(db, "feed", docData.id);
                docData["status"] = "approved";
                console.log("Updating a valid image: ");
                console.log(docData)
                batch.set(currDocRef, docData);
            }
        }
        await batch.commit();
        console.log("reset valid done");
    }

    const clearOldPendingItems = async () => {
        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - daysToKeep);
        
        const currentQuery = query(collection(db, "/feed"), where("status", "==", "pending"));
        const gallaryDocs = await getDocs(currentQuery);

        const batch = writeBatch(db);
        for (let currdoc of gallaryDocs.docs) {
            const docData = currdoc.data();
            const submittedDate = new Date(docData.submittedAt);
            
            if (submittedDate < thirtyDaysAgo) {
                const currDocRef = doc(db, "feed", docData.id);
                docData["status"] = "expired";
                batch.set(currDocRef, docData);
            }
        }
        await batch.commit();
    }

    return (
        <Container component="main" maxWidth="sm" style={{marginTop: 20, padding: 20}}>
            <h1>Dashboard</h1>

            <div>
                <Button onClick={moveApprovedImagesToPermant}>Move Approved Images to Permanent</Button>
            </div>

            <div>
                <Button onClick={removeExpiredImages}>Remove Expired Feeds</Button>
            </div>

            <div>
                <p><Button onClick={clearOldPendingItems}>Clear Feed </Button> up to &nbsp;
                <TextField
                    type="number"
                    value={daysToKeep}
                    onChange={(e) => setDaysToKeep(parseInt(e.target.value))}
                        size="small"
                    />&nbsp; days ago
                </p>
            </div>
        </Container>
    );
}