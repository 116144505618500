// reducer.js
import {doc, setDoc} from "firebase/firestore";
import {db} from "../firebase";


const approveRequest = request => {

    console.log(`Approving ${request.id}}`);

    setDoc(doc(db, "feed", request.id), request).finally(() => {
        console.log(`Approved ${request.id}`);
    });
    
    // setNotificationSettings({open: true, message: "request approved"});
    // setTimeout(() => setNotificationSettings({open: false, message: ""}), 5000)
};

const rejectRequest = request => {

    console.log(`Rejecting ${request.id}}`);

    setDoc(doc(db, "feed", request.id), request).finally(() => {
        console.log(`Rejected ${request.id}`);
    });
    // setNotificationSettings({open: true, message: "request rejected"});
    // setTimeout(() => setNotificationSettings({open: false, message: ""}), 5000)
};

export const diffusionApprovalState = {
    approved: [],
    rejected: []
};

export const approvalReducer = (state = diffusionApprovalState, action) => {
        switch (action.type) {
            case 'UPDATE_APPROVED':
                const existingApprovedItem = (state.approved || []).find(item => item.id === action.payload.id);
                if (existingApprovedItem) {
                    return state;
                }

                state.rejected = (state.rejected || []).filter(item => item.id !== action.payload.id);

                if (!state) {
                    state = diffusionApprovalState;
                }

                if (!state.approved) {
                    state.approved = [];
                }

                state.approved.push(action.payload);
                console.info(`${action.payload.id} APPROVED`);
                return state;

            case 'UPDATE_REJECTIONS':
                const existingRejectedItem = (state.rejected || []).find(item => item.id === action.payload.id);
                if (existingRejectedItem) {
                    return state;
                }

                state.approved = (state.approved || []).filter(item => item.id !== action.payload.id);
                
                if (!state) {
                    state = diffusionApprovalState;
                }

                if (!state.rejected) {
                    state.rejected = [];
                }

                state.rejected.push(action.payload);
                console.info(`${action.payload.id} REJECTED`);
                return state;

            case 'SUBMIT':
                console.log('APPROVING');
                const allApprovals = state.approved.map(item => item.id);
                console.table(allApprovals);
                
                console.log('REJECTING');
                const allRejections = state.rejected.map(item => item.id);
                console.table(allRejections);

                Promise.all(state.approved.map(item => approveRequest(item)))
                Promise.all(state.rejected.map(item => rejectRequest(item)));

                // clear state
                if (!state) {
                    return {
                        approved: [],
                        rejected: []
                    }
                }

                state = {
                    approved: [],
                    rejected: []
                };

                return state;
            default:
                console.table(state);
                return state;
        }
    }
;